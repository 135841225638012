<template>
  <div class="performancezz">
    <div class="header flex">
      <div class="dateName">统计日期</div>
      <van-dropdown-menu active-color="#3F7C53">
        <van-dropdown-item v-model="year" :options="option1" />
        <van-dropdown-item v-model="month" :options="option2" />
      </van-dropdown-menu>
    </div>
    <div class="flex num-wrap">
      <div class="num-item">
        <div class="name" @click="jumpClick">时点余额累计（万元）</div>
        <div class="numvalue">{{currBalance}}</div>
      </div>
      <div class="num-item">
        <div class="name">客户数累计</div>
        <div class="numvalue">{{cardNum}}</div>
      </div>
    </div>
    <van-pull-refresh v-model="isLoading"
                    @refresh="onRefresh">
      <van-list v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad">
        <div class="content-item" v-for="(item, index) in siteList" :key="index">
          <div class="content-title">{{item.statisticDate}}</div>
          <div class="table-title flex">
            <div>站点名称</div>
            <div><p>时点余额</p><p>(万元)</p></div>
            <div>客户数</div>
            <div><p>年日均</p><p>(万元)</p></div>
            <div><p>月日均</p><p>(万元)</p></div>
          </div>
          <div class="table-content flex">
            <div>{{item.stationName}}</div>
            <div>{{item.currBalance}}</div>
            <div>{{item.cardNum}}</div>
            <div>{{item.annualDayAvg}}</div>
            <div>{{item.monthDayAvg}}</div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    
  </div>
</template>

<script>
import { getPerformanceInfo, getSumPerformance } from '@/utils/api'
import common from '@/utils/common'
export default {
  data() {
    return{
      finished: false,
      isLoading: false,
      loading: false,
      pageIndex: 1,
      pageSize: 10,
      siteList:[],
      year: (new Date().getFullYear()).toString(),
      month: new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1).toString(),
      option1: [{ text: '2020', value: '2020' }, { text: '2021', value: '2021' }, { text: '2022', value: '2022' }, { text: '2023', value: '2023' }],
      option2: [
        { text: '1', value: '01' }, { text: '2', value: '02' }, { text: '3', value: '03' },{ text: '4', value: '04' },
        { text: '5', value: '05' }, { text: '6', value: '06' }, { text: '7', value: '07' },{ text: '8', value: '08' },
        { text: '9', value: '09' }, { text: '10', value: '10' }, { text: '11', value: '11' },{ text: '12', value: '12' },
      ],
      currBalance:0,
      cardNum: 0,
    }
  },
  mounted(){
    this.togetSumPerformance()
  },
  watch:{
    year(val){
      this.togetPerformanceInfo(val, this.month)
      this.togetSumPerformance(val, this.month)
      this.year = val
    },
    month(val){
      this.togetPerformanceInfo(this.year, val)
      this.togetSumPerformance(this.year, val)
      this.month = val
    }
  },
  methods:{
    jumpClick() {
      this.$router.push({name:'psInfo'})
    },
    togetSumPerformance(year, month) {
      getSumPerformance({
        uniqueNo: this.$route.query.jumpFrom !=undefined ? this.$route.query.uniqueNo : common.getItem('uniqueNo'),
        year,
        month,  
      }).then(res=>{
        if (res.code == 200) {
          if (res.data && res.data.businessCode == 200) {
            this.currBalance = res.data.data.currBalance
            this.cardNum = res.data.data.cardNum
          }
         }
      })
    },
    onRefresh() {
      this.isLoading = false
      this.siteList = []
      this.pageIndex = 1
      this.togetPerformanceInfo(this.year, this.month)
      this.togetSumPerformance(this.year, this.month)
    },
    onLoad () {
      this.togetPerformanceInfo(this.year, this.month)
      this.togetSumPerformance(this.year, this.month)
    },
    togetPerformanceInfo(year, month){
      getPerformanceInfo({
        uniqueNo: this.$route.query.jumpFrom !=undefined ? this.$route.query.uniqueNo : common.getItem('uniqueNo'),
        year,
        month,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      }).then(res=>{
        this.loading = false;
        if (res.code == 200) {
          if (res.data && res.data.pageModel && res.data.businessCode == 200) {
            this.finished = !res.data.pageModel.hasNextPage;
            if (this.pageIndex == 1) {
              this.siteList = res.data.pageModel.list;
            } else {
              this.siteList = this.siteList.concat(res.data.pageModel.list);
            }
            if (!this.finished) {
              this.pageIndex++;
            }

          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.performancezz{
  background: #F7F8F9;
  height: 100%;
  .header{
    width: 100%;
    background: #fff;
    .dateName{
      width: 2.5rem;
      flex: 0 0 2.5rem;
      text-align: center;
      line-height: .96rem;
      font-size: 0.3rem;
      color: #1E1E1E;
    }
  }
  .num-wrap{
    background: #fff;
    height: 1.5rem;
    align-items: center;
    .num-item{
      flex: 1;
      text-align: center;
      &:nth-child(1){
        border-right: 1px solid #eee;
      }
    }
    .name{
      color: #9B9B9B;
      font-size: 0.24rem;
    }
    .numvalue{
      color: #1E1E1E;
      font-size: 0.36rem;
    }
  }
  .content-item{
    background: #fff;
    margin-top: 0.24rem;
    padding: 0.4rem 0.3rem;
    .content-title{
      color: #4A4A4A;
      font-size: 0.3rem;
      margin-bottom: 0.4rem;
    }
    .table-title{
      color: #9B9B9B;
      font-size: 0.22rem;
      div{
        flex: 1;
        text-align: right;
        p{
          margin-bottom: 0;
        }
        &:nth-child(1){
          width:2rem;
          text-align: left;
        }
      }
    }
    .table-content{
      font-size: 0.26rem;
      color: #4A4A4A;
      div{
        text-align: right;
        flex: 1;
        &:nth-child(1){
          width:2rem;
          text-align: left;
        }
      }
    }
  }
}
</style>

<style lang="less">
.performancezz{
  .van-dropdown-menu{
    flex: 1;
    .van-dropdown-menu__bar{
      box-shadow: none;
    }
  }
}
</style>